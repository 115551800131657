import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { useEffect, useState, useContext, lazy, Suspense } from 'react'
import http from 'CommonJS/http'
import StudentBanner from 'Components/StudentBanner'
import { UserContext } from 'Context/UserContext'
import { FormStep, FormSubStep, StepInfo, Icon, ActionType, SubStepInfo } from './type'
import StepDetails from './StepDetails'
import Step from './Step'
import { subStepContext } from './context'
import { CgFileDocument } from 'react-icons/cg'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import { Button, SnackbarContent } from '@mui/material'
import Loading from 'Components/Loading'
import Checkbox from '@mui/material/Checkbox'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { BaseSingleInputFieldProps, DateValidationError, FieldSection } from '@mui/x-date-pickers/models'
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { toast } from 'Components/Toast/toast'
import noImagePlaceholder from '../../assets/images/no-image-placeholder.png'
import { AppStatusStyle, StatusEnum } from 'CommonJS/StatusEnum'
import { formatDate } from 'CommonJS/common'
import EventBus from 'CommonJS/eventBus'
import { isAdvisor, isStudent, isSubAdvisor, isSuperAdmin } from 'CommonJS/roleHelper'
import { ExternalLink } from './ExternalLink'
import { FormStepSlugEnum } from 'CommonJS/FormStepSlugEnum'
import { FormSubStepSlugEnum } from 'CommonJS/FormSubStepSlugEnum'
import NotesFooterBlock from './NotesFooterBlock'
import AdvisorInfo from './AdvisorInfo'
const HubspotTokenResolver = lazy(() => import('./HubspotTokenResolver'))

export default function StudentDashboard() {
  const { data } = useContext(UserContext)

  const [formStepsList, setFormStepsList] = useState<FormStep[]>([])
  const [stepInfo, setStepInfo] = useState<StepInfo | undefined>()
  const [openFormStepInfo, setOpenFormStepInfo] = useState<SubStepInfo | undefined>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [formSubStep, setFormSubStep] = useState<FormSubStep | null>(null)
  const [wantGravityForm, setWantGravityForm] = useState<boolean>(false)
  const match = useLocation()
  const { id } = useParams()
  const [openStepModal, setStepOpenModal] = useState<boolean>(false)
  const [formStep, setFormStep] = useState<FormStep | null>(null)
  const [instructionScreen, setInstructionScreen] = useState<boolean>(false)

  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [action, setAction] = useState<string>('')
  const [actionLabel, setActionLabel] = useState<string>('')
  const [initialValue, setInitialValue] = useState<string>('')
  const [savedLetterLink, setSavedLetterLink] = useState<string>('')
  const [icon, setActionIcon] = useState<Icon | null>(null)
  const [loading, setLoading] = useState(false)
  const [openText, setOpenText] = useState(false)
  const { state, dispatch } = useContext(subStepContext)
  const navigate = useNavigate()
  const [isViewOnly, setViewOnly] = useState<boolean>(false)
  const [event, setEvent] = useState<boolean | undefined>()
  const [subStep, setSubStep] = useState<any>()
  const [columnName, setColumnName] = useState<string>('')
  const [inputValue, setInputValue] = useState<string>()
  const [statusConfirm, setStatusConfirm] = useState<boolean>(false)

  let studentId: any
  if (match.pathname.includes('student-dashboard')) {
    studentId = id
  } else {
    studentId = data?.student?.id
  }

  const firstAcceptedIndex =
    data.roles.includes('student') && formStepsList.findIndex((step) => step.modifiedStatus == false) != -1
      ? formStepsList.findIndex((step) => step.modifiedStatus == false)
      : formStepsList.length

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    await setLoading(true)
    await getFormStepList()
    await getStepInfo()
    await getSubStepFormMeta()
    await setLoading(false)
  }
  function canPerformAction(showToast = true, defaultStepInfo = null) {
    const localStepInfo = defaultStepInfo ?? stepInfo
    const applicationStatus = localStepInfo?.applicationStatus ? localStepInfo?.applicationStatus : ''
    const can =
      localStepInfo?.deletedAt === null &&
      ![StatusEnum.REJECT as string, StatusEnum.WITHDRAW as string].includes(applicationStatus?.toLowerCase())

    if (!can && showToast) toast('Action cannot be performed on archived students.', 'warning')

    return can
  }

  async function getSubStepFormMeta() {
    try {
      const response = await http.fetch({ path: `student/${studentId}/meta` }).catch((error) => {
        toast(error?.response?.data.message ?? 'Internal server error.', 'error')
      })
      if (response?.data.success) {
        dispatch({ type: ActionType.SET_META, payload: response.data.message })
      }
    } catch (error) {
      toast('something went wrong please try again', 'error')
    }
  }

  const getFormStepList = () => {
    return http
      .fetch({ path: `student/formSteps/${studentId}` })
      .catch(({ response }) => {
        if (response?.status && response?.status === 404) {
          if (!isStudent(data.roles)) {
            toast('Student application not found', 'error')
            navigate('/')
          }
        } else {
          toast(response?.data.message ?? 'Internal server error.', 'error')
          setLoading(false)
        }
      })
      .then((response) => {
        if (response && response.status === 200) {
          setFormStepsList(response.data.formSteps)
          setActionIcon(response.data.setting)
        }
      })
  }

  const getStepInfo = () => {
    return http
      .fetch({ path: `students/${studentId}?needFormSteps=true` })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
        setLoading(false)
      })
      .then((response) => {
        if (response && response.status === 200) {
          setStepInfo(response.data.student)
          EventBus.$dispatch('student:info-updated', response.data.student)

          if (!canPerformAction(false, response.data.student)) {
            const styleTag = document.createElement('style')
            styleTag.classList.add('disable-action-dynamic-style')
            styleTag.textContent =
              '.main-content :has(> .MuiButtonBase-root), .main-content button.btn-primary, .main-content button.btn-secondary { opacity: 50% !important; cursor: not-allowed !important; }'
            document.head.appendChild(styleTag)
          } else {
            document.head.querySelectorAll('.disable-action-dynamic-style')?.forEach((e) => e.remove())
          }
        }
      })
  }

  const performAction = () => {
    setLoading(true)
    http
      .fetch({ method: 'put', path: `students/${studentId}/${action}` })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data.message, 'success')
          setConfirmModalOpen(false)
          if (response.data.stepStatus && stepInfo) {
            setStepInfo({
              ...stepInfo,
              step1Status: response.data.stepStatus?.step1Status,
              step2Status: response.data.stepStatus?.step2Status,
              step3Status: response.data.stepStatus?.step3Status,
              step4Status: response.data.stepStatus?.step4Status,
              applicationStatus: response.data.stepStatus?.applicationStatus
            })
          } else {
            getStepInfo()
          }
          if (action == 'rejectStudent') {
            navigate(0)
          }
        }
      })
  }

  const handleCheckboxChange = () => {
    if (!canPerformAction()) return
    type DueDateFields = 'dueDate' | 'invoiceBalanceDueDate'
    const dueDateFields: DueDateFields[] = ['dueDate', 'invoiceBalanceDueDate']
    const payload = {
      [columnName]: event === undefined ? (dueDateFields.includes(columnName as DueDateFields) && !inputValue ? '' : inputValue) : event
    }
    if (columnName === 'isRecheck' && subStep?.type === 'link' && payload[columnName]) {
      let slug = subStep.slug
      if (subStep.slug === FormSubStepSlugEnum.INSURANCE_PORTAL) {
        slug = 'insurance_link'
      }
      dispatch({ type: ActionType.SET_META, payload: { ...state.stepFormMeta, [slug]: null } })
    }
    setLoading(true)
    http
      .fetch({ method: 'put', path: `saveStepInfo/${studentId}/${subStep.id}`, data: payload })
      .catch((error) => {
        setLoading(false)
        toast(error?.response?.data?.message, 'error')
        setStatusConfirm(false)
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          if (stepInfo) {
            const updatedStudentStepInformation = stepInfo?.studentStepInformation.map((item) => {
              if (item.formSubStepId === subStep.id) {
                return response.data.stepInfo
              }
              return item
            })
            const updatedStepInfo = {
              ...stepInfo,
              studentStepInformation: updatedStudentStepInformation,
              step1Status: response.data.student.step1Status,
              step2Status: response.data.student.step2Status,
              step3Status: response.data.student.step3Status,
              step4Status: response.data.student.step4Status
            }
            setStepInfo(updatedStepInfo)
            if (response.data.formStep.id) {
              const updateStepList = formStepsList?.map((item) => {
                if (item.id === response.data.formStep.id) {
                  return { ...item, modifiedStatus: response.data.formStep.modifiedStatus }
                }
                return item
              })
              setFormStepsList(updateStepList)
            }
          }
          toast(response?.data?.message, 'success')
        }
        setStatusConfirm(false)
      })
  }

  const getLetter = (isView: boolean, action: string, label: string) => {
    if (!canPerformAction()) return
    if (['billingDepositDocument'].includes(action)) {
      setInstructionScreen(isView)
      setStepOpenModal(true)
      setAction(action)
      setActionLabel(label)
      setSavedLetterLink(String(stepInfo?.[action as keyof StepInfo]) ?? '')
    } else {
      http
        .fetch({ path: `setting/${studentId}?setting_column=${action}` })
        .catch(() => {
          toast('Internal server error.', 'error')
        })
        .then((response) => {
          if (response && response.status === 200) {
            setInstructionScreen(isView)
            setStepOpenModal(true)
            setAction(action)
            setActionLabel(label)
            if (response.data.setting?.[action]) {
              setInitialValue(response.data.setting?.[action])
            }
            setSavedLetterLink(response.data.setting?.letterLink)
          }
        })
    }
  }

  const saveLatter = async (value: string, settingType: string): Promise<boolean | null> => {
    if (settingType == 'instructions') {
      getFormStepList()
      return null
    }

    const payload = {
      [settingType]: value
    }
    let success = false
    await http
      .fetch({ method: 'put', path: `student-letter/${studentId}?setting_column=${settingType}`, data: payload })
      .catch((error) => {
        toast(error?.response?.data?.message, 'error')
      })
      .then((response) => {
        if (response && response.status === 200) {
          toast(response?.data?.message, 'success')
        }
        success = response?.data?.success
      })

    return success
  }

  const sendStudentLetter = (type: string): Promise<any> => {
    return http
      .fetch({ method: 'post', path: `student-letter/${studentId}/send`, data: { letterName: type } })
      .catch((error) => {
        setLoading(false)
        toast(error?.response?.data?.message, 'error')
      })
      .then((response) => {
        setConfirmModalOpen(false)
        if (response && response.status === 200) {
          toast(response?.data?.message, 'success')
          setStepOpenModal(false)
          setSavedLetterLink('')
        }
        setLoading(false)
      })
  }

  interface ButtonFieldProps
    extends UseDateFieldProps<Dayjs>,
      BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  }

  function ButtonField(props: ButtonFieldProps) {
    const { setOpen, label, id, InputProps: { ref } = {}, inputProps: { 'aria-label': ariaLabel } = {} } = props

    return (
      <label
        className={props.disabled ? '' : 'cursor-pointer hover:underline'}
        id={id}
        ref={ref}
        aria-label={ariaLabel}
        onClick={() => {
          if (!canPerformAction()) return
          setOpen?.((prev) => !prev)
        }}
      >
        {label ? `${label}` : (isSuperAdmin(data.roles) || isAdvisor(data.roles)) && 'Set Due Date'}
      </label>
    )
  }

  interface ButtonDatePickerProps extends Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'> {
    subStep: any
    defaultValue: any
    columnName?: string
  }

  function ButtonDatePicker(props: ButtonDatePickerProps) {
    const [open, setOpen] = useState(false)
    const { subStep, defaultValue } = props
    const [value, setValue] = useState<Dayjs | null>(defaultValue)
    return (
      <DatePicker
        slots={{ field: ButtonField, ...props.slots }}
        slotProps={{
          field: { setOpen } as any,
          actionBar: isStudent(data.roles) || isSubAdvisor(data.roles) ? {} : { actions: ['clear'] }
        }}
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        onChange={(newValue) => {
          setValue(newValue)
          setStatusConfirm(true)
          setEvent(undefined)
          setSubStep(subStep)
          setColumnName(props?.columnName ? props?.columnName : 'dueDate')
          setInputValue(newValue?.format('YYYY-MM-DD'))
        }}
        value={value}
        disableOpenPicker={isStudent(data.roles) || isSubAdvisor(data.roles)}
      />
    )
  }

  return (
    <>
      <Suspense fallback={<></>}>
        <HubspotTokenResolver />
      </Suspense>
      <div
        className={`main-content py-7 px-5 bg-[#e6e4e5] w-full h-[calc(100vh-77px)] overflow-auto md:px-4 lg:h-auto ${openModal ? 'model-open' : ''}`}
      >
        {stepInfo?.toBeDeleted && (
          <SnackbarContent message={'Notice: Schedule for deletion'} className='mt-2 !bg-red-600 !text-white !font-semibold' />
        )}
        <StudentBanner id={studentId} />
        <div className='mb-7 flex justify-between flex-wrap gap-1'>
          <div>
            <div className='gap-2 flex items-center'>
              <h2 className='text-3xl font-bold text-primary mb-2'>
                {data.roles.includes('student') ? 'Application Dashboard' : 'Student Application'}
              </h2>{' '}
              <button
                className='badge badge-primary capitalize text-center min-w-[100px]'
                style={stepInfo?.applicationStatus?.toLowerCase() ? AppStatusStyle[stepInfo?.applicationStatus?.toLowerCase()] : {}}
              >
                {stepInfo?.applicationStatus ?? '...'}
              </button>
            </div>
          </div>

          <AdvisorInfo advisor={stepInfo?.advisor} />
        </div>
        {loading && (
          <div className={`${!formStepsList || formStepsList?.length === 0 ? 'rounded-md bg-white p-4 mb-3 h-[80vh]' : ''}`}>
            <Loading />
          </div>
        )}

        {formStepsList &&
          formStepsList.length > 0 &&
          formStepsList.map((step, index) => {
            if (index < firstAcceptedIndex) {
              return (
                <div className={`${data.roles.includes('student') ? 'mb-5' : 'mb-20 md:mb-10'}`} key={index}>
                  <div className='grid grid-cols-2 mb-2 items-center lg:grid-cols-1'>
                    <div className='flex flex-row md:flex-col md:items-start items-center mb-2'>
                      <h4 className='mr-2 text-2xl font-semibold text-primary'>
                        {data.roles.includes('student') ? step?.titleForStudent : step.titleForAdmin}
                      </h4>
                      {step?.instructions && (
                        <button
                          className='btn-secondary py-1 px-4 bg-white font-semibold border-gray-800 w-max'
                          title={`${data.roles.includes('student') ? step?.titleForStudent : step.titleForAdmin} Instruction`}
                          onClick={() => {
                            setInstructionScreen(true)
                            setFormStep(step)
                            setStepOpenModal(true)
                            setAction('instructions')
                            setActionLabel(`${data.roles.includes('student') ? step?.titleForStudent : step.titleForAdmin} Instruction`)
                            setInitialValue(step.instructions)
                          }}
                        >
                          Read Me First
                        </button>
                      )}
                    </div>

                    <div className='text-end lg:text-start'>
                      <span
                        className={`${
                          step.slug == FormStepSlugEnum.STEP_ONE &&
                          (stepInfo?.step1Status == StatusEnum.ACCEPTED || stepInfo?.step1Status == StatusEnum.CONDITIONAL)
                            ? 'btn-primary bg-primary'
                            : step.slug == FormStepSlugEnum.STEP_TWO && stepInfo?.step2Status == StatusEnum.CONFIRM
                              ? 'btn-primary bg-primary'
                              : step.slug == FormStepSlugEnum.STEP_THREE && stepInfo?.step3Status == '1'
                                ? 'btn-primary bg-primary'
                                : step.slug == FormStepSlugEnum.STEP_FOUR &&
                                    stepInfo?.applicationStatus?.toLowerCase() == StatusEnum.COMPLETED
                                  ? 'btn-primary bg-primary'
                                  : 'btn-secondary bg-secondary'
                        } inline-block font-semibold text-white text-center w-96	 lg:w-auto uppercase`}
                      >
                        Status:{' '}
                        {step.slug === FormStepSlugEnum.STEP_ONE
                          ? stepInfo?.step1Status !== null
                            ? stepInfo?.step1Status
                            : 'Incomplete'
                          : step.slug === FormStepSlugEnum.STEP_TWO
                            ? stepInfo?.step2Status === null ||
                              stepInfo?.step2Status === 'pending' ||
                              stepInfo?.step2Status === 'Pending' ||
                              stepInfo?.step2Status === 'Processing' ||
                              stepInfo?.step2Status === 'processing'
                              ? 'Enrollment Pending'
                              : 'Enrollment Confirmed'
                            : step.slug === FormStepSlugEnum.STEP_THREE
                              ? stepInfo?.step3Status == 1 || stepInfo?.step3Status == true
                                ? 'Forms Completed'
                                : 'Incomplete'
                              : step.slug === FormStepSlugEnum.STEP_FOUR
                                ? stepInfo?.applicationStatus !== null && stepInfo?.applicationStatus?.toLowerCase() == StatusEnum.COMPLETED
                                  ? 'Application Complete'
                                  : 'Incomplete'
                                : 'Incomplete'}
                        !
                      </span>
                    </div>
                  </div>

                  <div className='rounded-md bg-white p-4 mb-3 md:px-1'>
                    <div className='grid grid-cols-5 lg:grid-cols-3 md:grid-cols-1 lg:gap-2'>
                      {step.formSubSteps.map((subStep, subindex) => (
                        <div className='text-center px-2 md:py-2' key={subindex}>
                          <div className='relative mb-2 rounded-full mx-auto flex items-center justify-center bg-primary-b w-28 h-28 lg:w-20 lg:h-20'>
                            {(stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isApproved &&
                              !stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isRecheck) ||
                            (stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isCompleted &&
                              !stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isRecheck) ? (
                              <img
                                src={icon?.completeStepIcon}
                                className='w-7 h-7 object-cover lg:w-5 lg:h-5 text-secondary bg-white rounded-full absolute top-2 right-0'
                              />
                            ) : (!stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isApproved &&
                                stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isRecheck) ||
                              (!stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isCompleted &&
                                stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isRecheck) ? (
                              <img
                                src={icon?.recheckStepIcon}
                                className='w-7 h-7 object-cover lg:w-5 lg:h-5 text-secondary bg-white rounded-full absolute top-2 right-0'
                              />
                            ) : (
                              stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isSubmitted && (
                                <img
                                  src={icon?.readyToReviewStepIcon}
                                  className='w-7 h-7 object-cover lg:w-5 lg:h-5 text-secondary bg-white rounded-full absolute top-2 right-0'
                                />
                              )
                            )}
                            {subStep?.icon ? (
                              <img src={subStep?.icon} alt='Icon' className='size-full rounded-full bg-[rgb(230,228,229)]' />
                            ) : (
                              <img src={noImagePlaceholder} alt='no-Icon' className='size-full rounded-full bg-[rgb(230,228,229)]' />
                            )}
                          </div>
                          <h3 className='text-xl lg:text-base font-medium'>{subStep?.name}</h3>
                          {
                            <div className='block cursor-pointer font-medium text-secondary hover:underline underline-offset-2'>
                              {!isSubAdvisor(data.roles) &&
                                ![
                                  FormSubStepSlugEnum.ACCEPTANCE_LETTER,
                                  FormSubStepSlugEnum.VISA_LETTER,
                                  FormSubStepSlugEnum.DEPOSIT_BILLING
                                ].includes(subStep.slug as FormSubStepSlugEnum) && (
                                  <div
                                    onClick={() => {
                                      setWantGravityForm(subStep.slug !== FormSubStepSlugEnum.COURSE_SCHEDULE)
                                      setOpenModal(true)
                                      setFormSubStep(subStep)
                                      setOpenFormStepInfo(
                                        stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                      )
                                      setViewOnly(false)
                                    }}
                                    className={
                                      (((index == 0 &&
                                        (stepInfo?.step1Status == StatusEnum.ACCEPTED ||
                                          stepInfo?.step1Status == StatusEnum.CONDITIONAL)) ||
                                        (index == 1 && stepInfo?.step2Status == StatusEnum.CONFIRM) ||
                                        (index == 2 && stepInfo?.step3Status == true) ||
                                        (index == 3 && stepInfo?.step4Status == StatusEnum.COMPLETED) ||
                                        (stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                          ?.isSubmitted &&
                                          !stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                            ?.isRecheck) ||
                                        (subStep?.hasApproved &&
                                          stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                            ?.isApproved) ||
                                        (subStep?.canBeMarkedAsCompleted &&
                                          stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                            ?.isCompleted) ||
                                        subStep?.type === 'link') &&
                                        isStudent(data.roles)) ||
                                      subStep?.slug === FormSubStepSlugEnum.VISA_APPLICATION_DOCUMENTS
                                        ? 'hidden'
                                        : ''
                                    }
                                  >
                                    {['form', 'link'].includes(subStep?.type)
                                      ? stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isSubmitted
                                        ? 'click to edit'
                                        : 'click to submit'
                                      : 'Click to Upload'}
                                  </div>
                                )}
                            </div>
                          }

                          {subStep.slug == FormSubStepSlugEnum.DEPOSIT_BILLING ? (
                            <ExternalLink
                              className='text-secondary block mb-1 cursor-pointer'
                              link={
                                stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.depositInvoiceLink ||
                                ''
                              }
                              label='(View)'
                              noLinkClickCallBack={() => {
                                toast('No invoice link found.', 'error')
                              }}
                            />
                          ) : (
                            <>
                              <div
                                className='block mb-1 cursor-pointer hover:underline underline-offset-2'
                                onClick={() => {
                                  if (subStep.slug == FormSubStepSlugEnum.ACCEPTANCE_LETTER) {
                                    getLetter(true, 'acceptanceLetter', 'Generate Acceptance Letter')
                                  } else if (subStep.slug == FormSubStepSlugEnum.VISA_LETTER) {
                                    getLetter(true, 'visaLetter', 'Generate Italian Visa Letter')
                                  } else if (subStep.slug == FormSubStepSlugEnum.VISA_APPLICATION_DOCUMENTS) {
                                    const visaUrl = data.roles.includes('student')
                                      ? '/visa-dashboard'
                                      : `/admin/visa-dashboard/${studentId}`
                                    navigate(visaUrl)
                                  } else {
                                    setWantGravityForm(subStep.slug !== FormSubStepSlugEnum.COURSE_SCHEDULE)
                                    setFormSubStep(subStep)
                                    setOpenFormStepInfo(
                                      stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                    )
                                    setOpenModal(true)
                                    setViewOnly(true)
                                  }
                                }}
                              >
                                {subStep.slug == FormSubStepSlugEnum.VISA_APPLICATION_DOCUMENTS ? '(Click to access)' : '(View Submission)'}
                              </div>
                              {subStep.slug == FormSubStepSlugEnum.COURSE_SCHEDULE && (
                                <div className='flex gap-1 flex-wrap items-center mx-auto border-t border-gray-500 py-2.5 justify-center'>
                                  <span className='whitespace-nowrap'>{'Confirm Final Schedule'}</span>
                                  <div className='flex gap-1.5'>
                                    {!(
                                      ((index == 0 &&
                                        (stepInfo?.step1Status == StatusEnum.ACCEPTED ||
                                          stepInfo?.step1Status == StatusEnum.CONDITIONAL)) ||
                                        (index == 1 && stepInfo?.step2Status == StatusEnum.CONFIRM) ||
                                        (index == 2 && stepInfo?.step3Status == true) ||
                                        (index == 3 && stepInfo?.step4Status == StatusEnum.COMPLETED) ||
                                        (stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                          ?.isSubmitted &&
                                          !stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                            ?.isRecheck) ||
                                        (subStep?.hasApproved &&
                                          stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                            ?.isApproved) ||
                                        (subStep?.canBeMarkedAsCompleted &&
                                          stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                            ?.isCompleted)) &&
                                      isStudent(data.roles)
                                    ) && !isSubAdvisor(data.roles) ? (
                                      <div
                                        className='text-secondary block cursor-pointer hover:underline underline-offset-2'
                                        onClick={() => {
                                          setWantGravityForm(true)
                                          setViewOnly(false)
                                          setFormSubStep(subStep)
                                          setOpenModal(true)
                                        }}
                                      >
                                        <span>{'(submit)'}</span>
                                      </div>
                                    ) : (
                                      <div
                                        className='block cursor-pointer hover:underline underline-offset-2'
                                        onClick={() => {
                                          setWantGravityForm(true)
                                          setViewOnly(true)
                                          setFormSubStep(subStep)
                                          setOpenFormStepInfo(
                                            stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                          )
                                          setOpenModal(true)
                                        }}
                                      >
                                        <span>{'(View Submission)'}</span>
                                      </div>
                                    )}
                                    {!isStudent(data.roles) && !isSubAdvisor(data.roles) && (
                                      <div
                                        className='block cursor-pointer hover:underline underline-offset-2'
                                        onClick={() => {
                                          setWantGravityForm(true)
                                          setViewOnly(true)
                                          setFormSubStep(subStep)
                                          setOpenFormStepInfo(
                                            stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                          )
                                          setOpenModal(true)
                                        }}
                                      >
                                        <span className='text-black'>/</span>
                                        <span className='ml-2'>{'(View Submission)'}</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {(data.roles.includes('superadmin') || data.roles.includes('advisor')) && (
                            <div className='flex flex-col items-start mx-auto w-full'>
                              {Boolean(subStep?.hasApproved) && (
                                <div className='block cursor-pointer'>
                                  <Checkbox
                                    id={`approve_${subStep.id}`}
                                    checked={
                                      stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isApproved ==
                                      true
                                    }
                                    onChange={(event) => {
                                      setStatusConfirm(true)
                                      setEvent(event?.target.checked)
                                      setSubStep(subStep)
                                      setColumnName('isApproved')
                                    }}
                                  />
                                  <label htmlFor={`approve_${subStep.id}`}> Approve</label>
                                </div>
                              )}

                              {Boolean(subStep?.canBeMarkedAsCompleted) && (
                                <div className='block cursor-pointer'>
                                  <Checkbox
                                    id={`is_completed_${subStep.id}`}
                                    checked={
                                      stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isCompleted ==
                                      true
                                    }
                                    onChange={(event) => {
                                      setStatusConfirm(true)
                                      setEvent(event?.target.checked)
                                      setSubStep(subStep)
                                      setColumnName('isCompleted')
                                    }}
                                  />
                                  <label htmlFor={`is_completed_${subStep.id}`}> Mark Complete</label>
                                </div>
                              )}

                              {(Boolean(subStep?.hasApproved) || Boolean(subStep?.canBeMarkedAsCompleted)) && (
                                <div className='block cursor-pointer'>
                                  <Checkbox
                                    id={`is_recheck_${subStep.id}`}
                                    onChange={(event) => {
                                      setStatusConfirm(true)
                                      setEvent(event?.target.checked)
                                      setSubStep(subStep)
                                      setColumnName('isRecheck')
                                    }}
                                    checked={
                                      stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.isRecheck ==
                                      true
                                    }
                                  />
                                  <label htmlFor={`is_recheck_${subStep.id}`}> Recheck</label>
                                </div>
                              )}
                              {subStep.slug == FormSubStepSlugEnum.DEPOSIT_BILLING && (
                                <>
                                  <div className='block cursor-pointer'>
                                    <Checkbox
                                      id={`deposit_paid_${subStep.id}`}
                                      checked={
                                        stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                          ?.depositPaid == true
                                      }
                                      onChange={(event) => {
                                        setStatusConfirm(true)
                                        setEvent(event?.target.checked)
                                        setSubStep(subStep)
                                        setColumnName('depositPaid')
                                      }}
                                    />
                                    <label htmlFor={`deposit_paid_${subStep.id}`}> Deposit Paid</label>
                                  </div>

                                  <div className='block cursor-pointer'>
                                    <Checkbox
                                      id={`invoice_paid_${subStep.id}`}
                                      checked={
                                        stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                          ?.invoicePaid == true
                                      }
                                      onChange={(event) => {
                                        setStatusConfirm(true)
                                        setEvent(event?.target.checked)
                                        setSubStep(subStep)
                                        setColumnName('invoicePaid')
                                      }}
                                    />
                                    <label htmlFor={`invoice_paid_${subStep.id}`}> Invoice Balance Paid</label>
                                  </div>
                                </>
                              )}
                              {Boolean(subStep?.canBeDisabled) && (
                                <div className='block cursor-pointer'>
                                  <Checkbox
                                    id={`step_enabled_${subStep.id}`}
                                    checked={
                                      stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.stepEnabled ==
                                      true
                                    }
                                    onChange={(event) => {
                                      setStatusConfirm(true)
                                      setEvent(event?.target.checked)
                                      setSubStep(subStep)
                                      setColumnName('stepEnabled')
                                    }}
                                  />
                                  <label htmlFor={`step_enabled_${subStep.id}`}>
                                    {subStep.slug == FormSubStepSlugEnum.VISA_LETTER
                                      ? 'Show Visa Icon'
                                      : subStep.slug == FormSubStepSlugEnum.INSURANCE_PORTAL
                                        ? 'Show Insurance Icon'
                                        : subStep.slug == FormSubStepSlugEnum.DEPOSIT_BILLING
                                          ? 'Show Billing Icon'
                                          : ' Show Icon'}
                                  </label>
                                </div>
                              )}

                              {subStep.slug == FormSubStepSlugEnum.DEPOSIT_BILLING && (
                                <>
                                  <div>
                                    <div className='mx-auto mt-3 flex gap-4'>
                                      <label>Invoice Link:</label>
                                      <div className='flex gap-1'>
                                        <button
                                          className='hover:underline'
                                          onClick={() => {
                                            if (!canPerformAction()) return
                                            setOpenText(true)
                                          }}
                                        >
                                          {!String(
                                            stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                              ?.depositInvoiceLink ?? ''
                                          )
                                            ? 'Add'
                                            : 'Change'}
                                        </button>
                                      </div>
                                    </div>
                                    <div className='mx-auto mt-3 flex gap-4'>
                                      <label>Deposit Due Date:</label>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <ButtonDatePicker
                                          label={
                                            stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                              ?.dueDate == null
                                              ? 'Set Due Date'
                                              : dayjs(
                                                  stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                                    ?.dueDate
                                                )?.format('MM/DD/YYYY')
                                          }
                                          subStep={subStep}
                                          defaultValue={
                                            stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                              ?.dueDate == null
                                              ? null
                                              : dayjs(
                                                  stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                                    ?.dueDate
                                                )
                                          }
                                        />
                                      </LocalizationProvider>
                                    </div>
                                    <div className='mx-auto mt-3 flex gap-4'>
                                      <label>Balance Due Date:</label>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <ButtonDatePicker
                                          columnName='invoiceBalanceDueDate'
                                          label={
                                            stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                              ?.invoiceBalanceDueDate == null
                                              ? 'Set Due Date'
                                              : dayjs(
                                                  stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                                    ?.invoiceBalanceDueDate
                                                )?.format('MM/DD/YYYY')
                                          }
                                          subStep={subStep}
                                          defaultValue={
                                            stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                              ?.invoiceBalanceDueDate == null
                                              ? null
                                              : dayjs(
                                                  stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                                    ?.invoiceBalanceDueDate
                                                )
                                          }
                                        />
                                      </LocalizationProvider>
                                    </div>
                                  </div>
                                  <Dialog
                                    open={openText}
                                    onClose={() => {
                                      setOpenText(false)
                                    }}
                                    fullWidth
                                    maxWidth='md'
                                    PaperProps={{
                                      component: 'form',
                                      onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                                        event.preventDefault()
                                        const formData = new FormData(event.currentTarget)
                                        const formJson = Object.fromEntries((formData as any).entries())
                                        const depositInvoiceLink = formJson.depositInvoiceLink
                                        setStatusConfirm(true)
                                        setEvent(undefined)
                                        setSubStep(subStep)
                                        setColumnName('depositInvoiceLink')
                                        setInputValue(depositInvoiceLink)
                                        setOpenText(false)
                                      }
                                    }}
                                  >
                                    <DialogTitle>Invoice Link</DialogTitle>
                                    <DialogContent>
                                      <TextField
                                        autoFocus
                                        required={false}
                                        margin='dense'
                                        id='depositInvoiceLink'
                                        name='depositInvoiceLink'
                                        type='text'
                                        fullWidth
                                        variant='standard'
                                        defaultValue={
                                          stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                            ?.depositInvoiceLink
                                        }
                                      />
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        onClick={() => {
                                          setOpenText(false)
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button type='submit'>Save</Button>
                                    </DialogActions>
                                  </Dialog>
                                </>
                              )}
                              {subStep.slug == FormSubStepSlugEnum.HEALTH_INFORMATION_FORM && (
                                <>
                                  <div className='block cursor-pointer'>
                                    <Checkbox
                                      id={`health_special_needs_requested_${subStep.id}`}
                                      checked={
                                        stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                          ?.healthSpecialNeedsRequested == true
                                      }
                                      onChange={(event) => {
                                        setStatusConfirm(true)
                                        setEvent(event?.target.checked)
                                        setSubStep(subStep)
                                        setColumnName('healthSpecialNeedsRequested')
                                      }}
                                    />
                                    <label htmlFor={`health_special_needs_requested_${subStep.id}`}> Health Special Needs Requested</label>
                                  </div>
                                </>
                              )}
                              {subStep.slug == FormSubStepSlugEnum.ACCOMMODATIONS_DOCUMENTS && (
                                <>
                                  <div className='block cursor-pointer'>
                                    <Checkbox
                                      id={`needs_no_accommodation_${subStep.id}`}
                                      checked={
                                        stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                          ?.needsNoAccommodation == true
                                      }
                                      onChange={(event) => {
                                        setStatusConfirm(true)
                                        setEvent(event?.target.checked)
                                        setSubStep(subStep)
                                        setColumnName('needsNoAccommodation')
                                      }}
                                    />
                                    <label htmlFor={`needs_no_accommodation_${subStep.id}`}>No Accommodations</label>
                                  </div>
                                  <div className='block cursor-pointer'>
                                    <Checkbox
                                      id={`needs_accommodation_requested_${subStep.id}`}
                                      checked={
                                        stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                          ?.needsAccommodationRequested == true
                                      }
                                      onChange={(event) => {
                                        setStatusConfirm(true)
                                        setEvent(event?.target.checked)
                                        setSubStep(subStep)
                                        setColumnName('needsAccommodationRequested')
                                      }}
                                    />
                                    <label htmlFor={`needs_accommodation_requested_${subStep.id}`}>Accommodations Requested</label>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                          <div className='mx-auto w-max'>
                            {(subStep.slug == FormSubStepSlugEnum.CONFIRM_ENROLLMENT ||
                              (subStep.slug == FormSubStepSlugEnum.DEPOSIT_BILLING &&
                                (isStudent(data.roles) || isSubAdvisor(data.roles)))) && (
                              <>
                                <div className='mx-auto mt-3 flex gap-4'>
                                  {stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.depositPaid ? (
                                    <label className='font-medium'>
                                      Deposit Paid{' - '}
                                      {formatDate(
                                        stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                          ?.depositPaidDate
                                      )}
                                    </label>
                                  ) : (
                                    <>
                                      {(subStep.slug !== FormSubStepSlugEnum.CONFIRM_ENROLLMENT ||
                                        stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.dueDate) && (
                                        <label>{subStep.slug === FormSubStepSlugEnum.DEPOSIT_BILLING && 'Deposit'} Due Date</label>
                                      )}
                                      {isStudent(data.roles) || isSubAdvisor(data.roles) ? (
                                        <div>
                                          {stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.dueDate
                                            ? formatDate(
                                                stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                                  ?.dueDate
                                              )
                                            : subStep.slug === FormSubStepSlugEnum.DEPOSIT_BILLING
                                              ? ' - '
                                              : null}
                                        </div>
                                      ) : (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                          <ButtonDatePicker
                                            label={
                                              stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                                ?.dueDate == null
                                                ? 'Enter Due Date'
                                                : formatDate(
                                                    stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                                      ?.dueDate
                                                  )
                                            }
                                            subStep={subStep}
                                            defaultValue={
                                              stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                                ?.dueDate == null
                                                ? null
                                                : dayjs(
                                                    stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                                      ?.dueDate
                                                  )
                                            }
                                          />
                                        </LocalizationProvider>
                                      )}
                                    </>
                                  )}
                                </div>
                                {subStep.slug !== FormSubStepSlugEnum.CONFIRM_ENROLLMENT && (
                                  <div className='mx-auto mt-3 flex gap-4'>
                                    {stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)?.invoicePaid ? (
                                      <label className='font-medium'>
                                        Invoice Paid{' - '}
                                        {formatDate(
                                          stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                            ?.invoiceBalancePaidDate
                                        )}
                                      </label>
                                    ) : (
                                      <>
                                        <label>Balance Due Date</label>
                                        <div>
                                          {stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                            ?.invoiceBalanceDueDate
                                            ? dayjs(
                                                stepInfo?.studentStepInformation?.find((item) => item?.formSubStepId === subStep?.id)
                                                  ?.invoiceBalanceDueDate
                                              )?.format('MM/DD/YYYY')
                                            : '-'}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='flex items-center flex-wrap gap-1 gap-x-3'>
                    {(data.roles.includes('superadmin') || data.roles.includes('advisor')) &&
                      (index == 3 ? (
                        <button
                          className='btn-primary py-1 px-4 bg-white font-semibold'
                          onClick={() => {
                            if (!canPerformAction()) return
                            setConfirmModalOpen(true)
                            setAction('completeApplication')
                            setActionLabel('Complete Application')
                          }}
                        >
                          Complete Application
                        </button>
                      ) : index == 2 ? (
                        <button
                          className='btn-primary py-1 px-4 bg-white font-semibold'
                          onClick={() => {
                            if (!canPerformAction()) return
                            setConfirmModalOpen(true)
                            setAction('confirmAllFormsCompleted')
                            setActionLabel('Confirm All Forms Completed')
                          }}
                        >
                          Confirm All Forms Completed
                        </button>
                      ) : index == 1 ? (
                        <>
                          <button
                            className='btn-primary py-1 px-4 bg-white font-semibold'
                            onClick={() => {
                              if (!canPerformAction()) return
                              setConfirmModalOpen(true)
                              setAction('confirmEnrollment')
                              setActionLabel('Confirm Enrollment')
                            }}
                          >
                            Confirm Enrollment
                          </button>
                          <button
                            className='btn-primary py-1 px-4 bg-white font-semibold'
                            onClick={() => {
                              if (!canPerformAction()) return
                              setConfirmModalOpen(true)
                              setAction('withdrawApplicant')
                              setActionLabel('Withdraw Applicant')
                            }}
                          >
                            Withdraw Applicant
                          </button>
                          <Link
                            to='#'
                            className='flex items-center gap-2'
                            onClick={() => {
                              getLetter(false, 'visaLetter', 'Generate Italian Visa Letter')
                            }}
                          >
                            <CgFileDocument className='w-6 h-6 mt-1 text-slate-400' />
                            <span>Generate Italian Visa Letter</span>
                          </Link>
                        </>
                      ) : (
                        index == 0 && (
                          <>
                            <button
                              className='btn-primary py-1 px-4 bg-white font-semibold'
                              onClick={() => {
                                if (!canPerformAction()) return
                                setConfirmModalOpen(true)
                                setAction('acceptStudent')
                                setActionLabel('Accept Student')
                              }}
                            >
                              Accept Student
                            </button>
                            <button
                              className='btn-primary py-1 px-4 bg-white font-semibold'
                              onClick={() => {
                                if (!canPerformAction()) return
                                setConfirmModalOpen(true)
                                setAction('conditionallyAccept')
                                setActionLabel('Conditionally Accept')
                              }}
                            >
                              Conditionally Accept
                            </button>
                            <button
                              className='btn-secondary py-1 px-4 bg-white font-semibold'
                              onClick={() => {
                                if (!canPerformAction()) return
                                setConfirmModalOpen(true)
                                setAction('rejectStudent')
                                setActionLabel('Reject Student')
                              }}
                            >
                              Reject Student
                            </button>
                            <Link
                              to='#'
                              className='flex items-center gap-2'
                              onClick={() => {
                                getLetter(false, 'acceptanceLetter', 'Generate Acceptance Letter')
                              }}
                            >
                              <CgFileDocument className='w-6 h-6 mt-1 text-slate-400' />
                              <span>Generate Acceptance Letter</span>
                            </Link>
                          </>
                        )
                      ))}
                    {(data.roles.includes('superadmin') || data.roles.includes('advisor')) && (
                      <>
                        <Link
                          to='#'
                          className='flex gap-2 items-center'
                          onClick={() => {
                            if (!canPerformAction()) return
                            setAction('instructions')
                            setActionLabel('Instruction')
                            setInstructionScreen(false)
                            setFormStep(step)
                            setStepOpenModal(true)
                            setInitialValue(step.instructions)
                          }}
                        >
                          <BsQuestionCircleFill className='w-6 h-6 mt-1 text-slate-400' />
                          <span>Edit Section Instructions</span>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              )
            }
            return null
          })}

        <NotesFooterBlock
          canPerformAction={(showToast, defaultStepInfo) => canPerformAction(showToast ?? true, defaultStepInfo ?? null)}
          setLoading={setLoading}
          visaPortalStepInfo={stepInfo}
          getVisaPortalStepInfo={getStepInfo}
        />
      </div>

      {openModal && (
        <StepDetails
          setOpenModal={setOpenModal}
          wantGravityForm={wantGravityForm}
          formSubStep={formSubStep}
          formStepInformation={openFormStepInfo}
          disabled={!canPerformAction(false) || isSubAdvisor(data.roles)}
          disabledCallback={(notify) => canPerformAction(notify)}
          saveInfoCallback={(status: { step1Status: string; step2Status: string; step3Status: string; step4Status: string }) => {
            if (stepInfo) {
              setStepInfo((oldVal: any) => {
                return {
                  ...oldVal,
                  step1Status: status?.step1Status,
                  step2Status: status?.step2Status,
                  step3Status: status?.step3Status,
                  step4Status: status?.step4Status
                }
              })
            }
          }}
          isViewOnly={isViewOnly}
          init={init}
          getSubStepFormMeta={getSubStepFormMeta}
        />
      )}

      {openStepModal && (
        <Step
          setOpenModal={(val) => {
            setStepOpenModal(val)
            if (!val) setSavedLetterLink('')
          }}
          formSubStep={formStep}
          isView={instructionScreen}
          type={action}
          label={actionLabel}
          initialValue={initialValue}
          callSave={saveLatter}
          additionalInfo={{
            savedLetterLink: savedLetterLink
          }}
          sendLetter={() => {
            return new Promise(() => {
              setConfirmModalOpen(true)
              setActionLabel('Send Letter')
            })
          }}
        />
      )}

      <div>
        <ConfirmationAlert
          title={'Are you sure you perform this action ?'}
          isConfirmModalOpen={isConfirmModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          ButtonText={'Yes I am sure'}
          actionName={actionLabel}
          onAction={() => {
            if (actionLabel == 'Send Letter') {
              setLoading(true)
              sendStudentLetter(action)
            } else {
              performAction()
            }
          }}
        />
      </div>

      <ConfirmationAlert
        title={'Are you sure you perform this action ?'}
        isConfirmModalOpen={statusConfirm}
        setConfirmModalOpen={setStatusConfirm}
        ButtonText={'Yes I am sure'}
        onAction={() => handleCheckboxChange()}
      />
    </>
  )
}
